import React from 'react'
import '../components/all.sass'

class PrintButton extends React.Component {
  handleClick() {
    window.print()
    console.log('this is:', this)
  }

  render() {
    // binds `this` within handleClick
    return (
      <block className="block noprint">
        <button
          className="button is-info noprint"
          onClick={() => this.handleClick()}
        >
          Print Template
        </button>
        <br></br>
        <br></br>
      </block>
    )
  }
}

export default PrintButton
