import React from 'react'
import { Link } from 'gatsby'
import '../components/all.sass'

const BreadCrumb = function ({ filePath }) {
  const parts = filePath.split('/')
  return (
    <section className="noprint section sectionbody">
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li>
            <Link to="/">Product Listing</Link>
          </li>
          {parts[0] && (
            <li>
              <Link to={`/${parts[0]}`}>{parts[0]}</Link>
            </li>
          )}
          {parts[2] && (
            <li className="is-active">
              <Link to={`/${parts[0]}/${parts[2]}`}>{parts[2]}</Link>
            </li>
          )}
        </ul>
      </nav>
    </section>
  )
}

export default BreadCrumb
