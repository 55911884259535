import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { logout } from '../utils/auth'
import { StaticImage } from 'gatsby-plugin-image'
import '../components/all.sass'
import { getProfile } from '../utils/auth'

const Navbar = function ({ title }) {
  const user = getProfile()
  const data = useStaticQuery(graphql`
    {
      allSitePage(filter: { path: { regex: "/^/[A-Za-z0-9_]+$/" } }) {
        nodes {
          path
        }
      }
    }
  `)
  const productPaths = data.allSitePage.nodes
  return (
    <React.Fragment>
      <nav
        className="navbar is-info is-fixed-top"
        role="navigation"
        aria-label="dropdown navigation"
      >
        <div className="navbar-menu">
          <div
            className="navbar-brand navbar-start"
            style={{ height: '40px', width: '55px', margin: '5px' }}
          >
            <StaticImage
              src="../images/favicon.png"
              alt="TZ Medical Logo"
              width={400}
            />
          </div>
          <div className="navbar-end">
            <div className="navbar-item has-dropdown is-hoverable is-size-6">
              <a className="navbar-link" href="/">
                Product listing
              </a>
              <div className="navbar-dropdown is-right">
                {productPaths.map((node) => (
                  <Link className="navbar-item" to={node.path}>
                    {node.path.replace('/', '')}
                  </Link>
                ))}
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable is-size-6">
              <a className="navbar-link">Hello, {user.given_name}</a>
              <div className="navbar-dropdown is-right">
                <a className="navbar-item">User: {user.name}</a>
                <a className="navbar-item is-size-8">Email: {user.email}</a>
                <hr className="navbar-divider" />
                <a
                  className="navbar-item is-size-6 is-size-8"
                  href="#logout"
                  onClick={(e) => {
                    logout()
                    e.preventDefault()
                  }}
                >
                  Log Out
                </a>
                <hr className="navbar-divider" />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </React.Fragment>
  )
}

export default Navbar
