import React from 'react'
import { login, isAuthenticated, isBrowser } from '../utils/auth'
import { Helmet } from 'react-helmet'
import { capitalCase } from 'change-case'
import useSiteMetadata from '../components/SiteMetadata'
import Navbar from './Navbar'
import TableOfContents from './TableOfContents'
import PrintButton from './PrintTemplate'
import BreadCrumb from './BreadCrumb'
import './all.sass'

const Layout = ({ title, filePath, tableOfContents, children }) => {
  const { title: siteTitle } = useSiteMetadata()
  if (!title) title = `${siteTitle}`
  else title = capitalCase(`${siteTitle} - ${title}`)

  if (!isAuthenticated() && isBrowser) {
    // Store current pathname to local storage for redirection after auth0
    localStorage.setItem('authFromURL', window.location.pathname)
    login()
    return <p>Redirecting to login...</p>
  }
  // adds an id to each header element in children
  let result = getHeaderNodes(React.Children.toArray(children))
  return (
    <React.Fragment>
      <Helmet>
        <html lang="en" className="has-navbar-fixed-top" />
        <title>{title}</title>
      </Helmet>
      <Navbar title className="is-small" />
      <section className="section sectionhead">
        {filePath && <BreadCrumb className="noprint" filePath={filePath} />}
        <h1 className="title is-1 doctitle">{title}</h1>
        {filePath && filePath.split('/').length > 1 && (
          <PrintButton className="noprint" />
        )}

        <section className="block">
          {isAuthenticated() && tableOfContents && (
            <TableOfContents headers={result.headers} />
          )}
        </section>
        <section className="block">
          {isAuthenticated() && result.children}
        </section>
      </section>
      {/* <Footer /> */}
    </React.Fragment>
  )
}

/**
 * Recursively travels through the document elements to find headers.
 * If a header is found, it gets a unique id.
 * @param  nodes      the DOM elements (children)
 * @param  parentName the name of the current level of recursion
 * @return            the array of headers and the new updated document elements
 */
function getHeaderNodes(nodes, parentName = 'root') {
  const headerClassNames = ['h1', 'h2', 'h3']
  let result = { headers: [], children: [] }
  //look through all child elements
  for (var j = 0, length = nodes.length; j < length; j++) {
    let element = nodes[j]
    let results = { headers: [], children: [] }

    //skip if element has no children
    if (nodes[j].props && nodes[j].props.children) {
      results = getHeaderNodes(
        React.Children.toArray(nodes[j].props.children),
        `${parentName}-${j.toString()}`
      )
      result.headers.push.apply(result.headers, results.headers)
      element = (
        <element.type {...element.props}>{results.children}</element.type>
      )
    }

    //if a header is found, give it an id.
    if (headerClassNames.includes(nodes[j].type)) {
      element = (
        <element.type
          {...element.props}
          {...{ id: `${parentName}-${j.toString()}` }}
        >
          {results.children}
        </element.type>
      )
      result.headers.push(element)
    }

    //if an inspection procedure is found, add the ehaders inside it
    if (nodes[j].type && nodes[j].type.name === 'ProcedureListing') {
      nodes[j].props.procedure.map((step, i) =>
        result.headers.push(
          <h2 id={`procedure-${j}-${i}`}>{step.procedureDescription}</h2>
        )
      )
    }

    if (nodes[j].type && nodes[j].type.name === 'VersionReleaseNotes') {
      nodes[j].props.versionNotes.map((version, i) =>
        result.headers.push(
          <h2 id={version.versionNumber}>Version {version.versionNumber}</h2>
        )
      )
    }

    result.children.push(element)
  }
  return result
}

export default Layout
