import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import './all.sass'

const TableOfContents = ({ headers }) => {
  return (
    <div>
      <h1 className="title is-3">Table Of Contents</h1>
      {headers.map((header) => (
        <div>
          {header.type === 'h1' && (
            <h3 className="tocheader">
              <AnchorLink to={`#${header.props.id}`}>
                {header.props.children}
              </AnchorLink>
            </h3>
          )}
          {header.type === 'h2' && (
            <h3 className="pl-3 toc">
              <AnchorLink to={`#${header.props.id}`}>
                {header.props.children}
              </AnchorLink>
            </h3>
          )}
          {header.type === 'h3' && (
            <h3 className="pl-6 toc">
              <AnchorLink to={`#${header.props.id}`}>
                {header.props.children}
              </AnchorLink>
            </h3>
          )}
        </div>
      ))}
    </div>
  )
}

export default TableOfContents
